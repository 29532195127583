.panel-3d {
  width: 420px;
  padding: 0px;
  margin: 0px;
  background-color: white;
  box-shadow: #00000069 0px 0px 10px 0px;
}

.panel-3d .panel-3d-header {
  background-color: #3f51b5;
  display: flex;
  justify-content: space-between;
  cursor: move;
}

.panel-3d .panel-3d-header {
  color: white;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.panel-3d .panel-3d-header h1,
.panel-3d .panel-3d-header .close {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0px;
  padding: 10px 10px;
  cursor: pointer;
}

.panel-3d .panel-3d-header h1 {
  font-size: 18px;
  padding-top: 17px;
}

.panel-3d .panel-3d-toolbar {
  display: flex;
  justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid #000000;
}

.panel-3d .panel-3d-toolbar .right-group {
  width: 20%;
}
.panel-3d .panel-3d-toolbar .right-group,
.panel-3d .panel-3d-toolbar .left-group {
  padding: 7px;
}
.panel-3d .panel-3d-toolbar .left-group {
  text-align: left;
  width: 80%;
}

.panel-3d .panel-3d-toolbar .right-group {
  text-align: right;
}

.panel-3d .panel-3d-toolbar .temp,
.panel-3d .panel-3d-toolbar .windows,
.panel-3d .panel-3d-toolbar .occupation,
.panel-3d .panel-3d-toolbar .co2,
.panel-3d .panel-3d-toolbar .setpoint {
  display: inline-block;
}

.panel-3d .panel-3d-toolbar .temp,
.panel-3d .panel-3d-toolbar .setpoint,
.panel-3d .panel-3d-toolbar .co2 {
  margin-right: 10px;
}

.panel-3d .panel-3d-toolbar .temp span,
.panel-3d .panel-3d-toolbar .setpoint span,
.panel-3d .panel-3d-toolbar .co2 span {
  display: inline-block;
  height: 20px;
  vertical-align: middle;
}
.panel-3d .panel-3d-toolbar .co2 img {
  margin-right: 5px;
}

.panel-3d .panel-3d-toolbar .setpoint .sunny {
  margin-right: 5px;
}
.panel-3d .panel-3d-toolbar .temp svg,
.panel-3d .panel-3d-toolbar .setpoint svg {
  vertical-align: middle;
}

.panel-3d .panel-3d-toolbar .windows {
  margin-right: 10px;
}

.panel-3d .panel-3d-body {
  text-align: center;
}

.panel-3d .panel-3d-body .shutdown {
  margin-top: 15px;
  cursor: pointer;
}

.panel-3d .panel-3d-body .setpoint {
  margin: 15px 0;
}
.panel-3d .panel-3d-body .slider {
  display: inline-block;
  margin: 10px;
}

.panel-3d .panel-3d-body .fan-speed {
  padding: 10px;
}
.panel-3d .panel-3d-body .fan-speed div {
  display: inline-block;
  margin: 10px 15px;
}

.panel-3d .panel-3d-body .fan-speed div svg {
  vertical-align: middle;
  cursor: pointer;
  fill: lightgray;
}

.panel-3d .panel-3d-body .fan-speed div .activeFan100 {
  animation: spin 0.55s linear infinite;
}
.panel-3d .panel-3d-body .fan-speed div .activeFan75 {
  animation: spin 0.7s linear infinite;
}

.panel-3d .panel-3d-body .fan-speed div .activeFan50 {
  animation: spin 1.4s linear infinite;
}

.panel-3d .panel-3d-body .fan-speed div .activeFan25 {
  animation: spin 2.8s linear infinite;
}

.panel-3d .panel-3d-body .fan-speed div .activeFan {
  fill: #3f51b5;
}

.inactive-svg {
  fill: lightgray;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
