/** forge ext*/
body {
  margin: 0;
  padding: 0;
}

#root {
  display: grid;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
  grid-template-columns: 100%;
}

.ForgeViewer {
  width: 100%²;
  height: 100%;
  margin: 0 auto;
  /* transform: rotateX(0deg) rotateY(0deg);
  transition: all 2s; */
}

#MyControls {
  position: absolute;
  /* top: 5px; */
  /* left: 5px; */
  right: 15px;
  bottom: 15px;
  z-index: 1;
  /* padding: 1em; */
  /* font-size: 1em; */
  /* cursor: pointer; */
  /* box-shadow: 5px 5px 5px #888888; */
  border: 1px solid #d4d4d4;
  background-color: white;
  border-radius: 5px;
}

#autodesk_logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
}

#autodesk_logo img {
  max-width: 50%;
}

.element {
  /*background-color: red;*/
  background-color: rgba(0, 127, 127, 0.5);
  width: 120px;
  height: 160px;
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
  border: 1px solid rgba(127, 255, 255, 0.25);
  text-align: center;
  overflow: scroll;
}
.element:hover {
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
}

.element > h4 {
  pointer-events: none;
}

.cssOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
}

.cssOverlay .panel-3d {
  color: #d4d4d4;
  background-color: black;
  opacity: 0.7;
  border-radius: 5px;
}

.cssOverlay .panel-3d td {
  padding: 5px;
}

/** forge ext*/

.demo-layout-transparent .mdl-layout__header,
.demo-layout-transparent .mdl-layout__drawer-button {
  /* This background is dark, so we set text to white. Use 87% black instead if
     your background is light. */
  color: white;
}
.demo-list-icon {
  width: 300px;
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  display: flex !important;
  align-items: center !important;
  padding: 16px;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link i {
  margin-right: 16px;
}
.mdl-layout__header .mdl-layout-title img {
  width: 90px;
}

.mdl-layout__content .my-fab {
  background-color: #3f51b5;
  position: absolute;
  bottom: 35px;
  right: 35px;
  color: white;
  z-index: 100;
}
dialog.mdl-dialog#dialog-about {
  width: 600px;
}

dialog.mdl-dialog button.close {
  background-color: #3f51b5 !important;
  color: white;
}
dialog.mdl-dialog#dialog-login .person-icon i {
  font-size: 80px;
}
.mdl-layout__drawer i.logout {
  cursor: pointer;
}
dialog.mdl-dialog#dialog-login .mdl-dialog__actions {
  justify-content: center;
}
dialog.mdl-dialog#dialog-login .mdl-dialog__actions button {
  text-align: center;
  margin-bottom: 10px;
  flex: 0 0 80%;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.capitalise {
  text-transform: capitalize;
}
