.value-picker {
  display: flex;
  justify-content: center;
}

.value-picker span {
  display: inline-block;
  margin: 0 5px;
}
.value-picker span.bullet {
  cursor: pointer;
}

.value-picker .min-value,
.value-picker .max-value {
  text-align: center;
  padding-top: 2px;
  width: 30px;
}
